import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeBooking, selectBooking } from 'store/booking/booking.slice';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { BookingBody } from './BookingBody/BookingBody';

export const Booking = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const booking = useSelector(selectBooking);

  return (
    <Transition isActive={booking.ui.bookingIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeBooking())}
            className={transitionStyles}
          >
            <BookingBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
