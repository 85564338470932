import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { FormState, FormType } from './form.types';

const initialState: FormState = {
  type: 'calculation',
  arrival: null,
  departure: null,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    modalIsOpen: false,
    arrivalIsOpen: false,
    departureIsOpen: false,
    guestsIsOpen: false,
  },
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    changeFormType: (state, { payload }: PayloadAction<FormType>) => {
      state.type = payload;
    },

    setFormArrival: (state, { payload }: PayloadAction<number | null>) => {
      state.arrival = payload;
    },
    setFormDeparture: (state, { payload }: PayloadAction<number | null>) => {
      state.departure = payload;
    },

    changeFormAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeFormBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setFormBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },

    setFormName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setFormPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    toggleFormPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    openFormModal: (state) => {
      state.ui.modalIsOpen = true;
    },
    closeFormModal: (state) => {
      state.ui.modalIsOpen = false;
    },

    openFormArrival: (state) => {
      state.ui.arrivalIsOpen = true;
    },
    closeFormArrival: (state) => {
      state.ui.arrivalIsOpen = false;
    },

    openFormDeparture: (state) => {
      state.ui.departureIsOpen = true;
    },
    closeFormDeparture: (state) => {
      state.ui.departureIsOpen = false;
    },

    openFormGuests: (state) => {
      state.ui.guestsIsOpen = true;
    },
    closeFormGuests: (state) => {
      state.ui.guestsIsOpen = false;
    },

    clearForm: (state) => {
      state.type = 'calculation';
      state.arrival = null;
      state.departure = null;
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },
  },
});

export const {
  changeFormType,

  setFormArrival,
  setFormDeparture,

  changeFormAdults,
  changeFormBabies,
  setFormBaby,

  setFormName,
  setFormPhone,
  toggleFormPolicy,

  openFormModal,
  closeFormModal,

  openFormArrival,
  closeFormArrival,

  openFormDeparture,
  closeFormDeparture,

  openFormGuests,
  closeFormGuests,

  clearForm,
} = formSlice.actions;

export const selectForm = (state: RootState) => state.form;

export const formReducer = formSlice.reducer;
