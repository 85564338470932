import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { CallbackState } from './callback.types';

const initialState: CallbackState = {
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    callbackIsOpen: false,
  },
};

export const callbackSlice = createSlice({
  name: 'callback',
  initialState,
  reducers: {
    setCallbackName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setCallbackPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    toggleCallbackPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    openCallback: (state) => {
      state.ui.callbackIsOpen = true;
    },
    closeCallback: (state) => {
      state.ui.callbackIsOpen = false;
    },
    clearCallback: (state) => {
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },
  },
});

export const {
  setCallbackName,
  setCallbackPhone,
  toggleCallbackPolicy,

  openCallback,
  closeCallback,
  clearCallback,
} = callbackSlice.actions;

export const selectCallback = (state: RootState) => state.callback;

export const callbackReducer = callbackSlice.reducer;
