import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { QuestionState } from './question.types';

const initialState: QuestionState = {
  name: '',
  phone: '',
  policyIsChecked: false,
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setQuestionName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setQuestionPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    toggleQuestionPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    clearQuestion: (state) => {
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },
  },
});

export const {
  setQuestionName,
  setQuestionPhone,
  toggleQuestionPolicy,

  clearQuestion,
} = questionSlice.actions;

export const selectQuestion = (state: RootState) => state.question;

export const questionReducer = questionSlice.reducer;
