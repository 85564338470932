import { DatePicker } from 'components/forms';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  closeQuizArrival,
  closeQuizDeparture,
  openQuizArrival,
  openQuizDeparture,
  selectQuiz,
  setQuizArrival,
  setQuizDeparture,
} from 'store/quiz/quiz.slice';
import styles from './QuizOne.module.scss';

export const QuizOne = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <DatePicker
        className={styles.arrival}
        name="Дата заезда"
        isOpen={quiz.ui.arrivalIsOpen}
        disabledDaysBefore={new Date()}
        onClick={() =>
          dispatch(
            quiz.ui.arrivalIsOpen ? closeQuizArrival() : openQuizArrival()
          )
        }
        onOutsideClick={() => dispatch(closeQuizArrival())}
        onDayClick={(date) => {
          batch(() => {
            dispatch(setQuizArrival(date.valueOf()));
            dispatch(closeQuizArrival());
          });
        }}
        value={quiz.arrival}
      />
      <DatePicker
        className={styles.departure}
        name="Дата отъезда"
        isOpen={quiz.ui.departureIsOpen}
        disabledDaysBefore={quiz.arrival ? new Date(quiz.arrival) : new Date()}
        onClick={() =>
          dispatch(
            quiz.ui.departureIsOpen ? closeQuizDeparture() : openQuizDeparture()
          )
        }
        onOutsideClick={() => dispatch(closeQuizDeparture())}
        onDayClick={(date) => {
          batch(() => {
            dispatch(setQuizDeparture(date.valueOf()));
            dispatch(closeQuizDeparture());
          });
        }}
        value={quiz.departure}
      />
    </div>
  );
};
