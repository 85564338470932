import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeCallback, selectCallback } from 'store/callback/callback.slice';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { CallbackBody } from './CallbackBody/CallbackBody';

export const Callback = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const callback = useSelector(selectCallback);

  return (
    <Transition isActive={callback.ui.callbackIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeCallback())}
            className={transitionStyles}
          >
            <CallbackBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
