import { FormEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { TextField } from 'components/forms';
import { CloseButton, Policy } from 'components/elements';
import styles from './FormModalBody.module.scss';
import { openTextModal, openThanks } from 'store/app/app.slice';
import {
  clearForm,
  closeFormModal,
  openFormArrival,
  openFormDeparture,
  selectForm,
  setFormName,
  setFormPhone,
  toggleFormPolicy,
} from 'store/form/form.slice';

declare const ym: any;
// declare const fbq: any;

export const FormModalBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useSelector(selectForm);

  const {
    type,
    arrival,
    departure,
    adults,
    babies,
    name,
    phone,
    policyIsChecked,
  } = form;

  const title = {
    booking: 'Бронирование',
    calculation: 'Расчет стоимости',
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (arrival === null) {
      dispatch(openFormArrival());
      return;
    }
    if (departure === null) {
      dispatch(openFormDeparture());
      return;
    }

    ym(86936451, 'reachGoal', 'kick');
    // fbq('track', 'Lead');

    dispatch(closeFormModal());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: title[type],

        arrival: new Date(arrival).toLocaleDateString(),
        departure: new Date(departure).toLocaleDateString(),
        adults,
        babies,
        name,
        phone,
      }),
    });

    dispatch(clearForm());
  };

  return (
    <div className={styles.module}>
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeFormModal())}
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>
          {form.type === 'booking' ? 'Бронирование' : 'Расчет стоимости'}
        </h2>
        <TextField
          className={styles.name}
          placeholder="Имя"
          value={form.name}
          onChange={(event) => dispatch(setFormName(event.target.value))}
        />
        <TextField
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="\d+"
          required
          value={form.phone}
          onChange={(event) => dispatch(setFormPhone(event.target.value))}
        />
        <Policy
          className={styles.policy}
          checked={policyIsChecked}
          onChange={() => dispatch(toggleFormPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
