import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Form } from 'views/Form';

const initForm = () => {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <Form />
      </Provider>
    </StrictMode>,
    document.getElementById('form')
  );
};

const form = {
  init: () => {
    initForm();
  },
};

export default form;
