import cn from 'classnames';
import { DropdownProps } from './Dropdown.props';
import styles from './Dropdown.module.scss';
import { useLayoutEffect, useRef } from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { getElementOffset } from 'utils/helpers/browser.helpers';

export const Dropdown = ({
  faceRef,
  onOutsideClick,
  children,
  position = 'bottomLeft',
  className,
}: DropdownProps): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick([faceRef, dropdownRef], onOutsideClick);

  useLayoutEffect(() => {
    if (!faceRef.current || !dropdownRef.current) {
      throw Error('Ref is not assigned');
    }

    const faceEl = faceRef.current;
    const dropdownEl = dropdownRef.current;

    // const faceBox = faceEl.getBoundingClientRect();
    // const dropdownBox = dropdownEl.getBoundingClientRect();
    // const { clientHeight, clientWidth } = document.documentElement;

    const { top, left } = getElementOffset(faceEl);

    if (position === 'topLeft') {
      dropdownEl.style.top = top - dropdownEl.offsetHeight + 'px';
      dropdownEl.style.left = left + 'px';
    } else if (position === 'topCenter') {
      dropdownEl.style.top = top - dropdownEl.offsetHeight + 'px';
      dropdownEl.style.left =
        left - dropdownEl.offsetWidth / 2 + faceEl.offsetWidth / 2 + 'px';
    } else if (position === 'topRight') {
      dropdownEl.style.top = top - dropdownEl.offsetHeight + 'px';
      dropdownEl.style.left =
        left + faceEl.offsetWidth - dropdownEl.offsetWidth + 'px';
    } else if (position === 'bottomLeft') {
      dropdownEl.style.top = top + faceEl.offsetHeight + 'px';
      dropdownEl.style.left = left + 'px';
    } else if (position === 'bottomRight') {
      dropdownEl.style.top = top + faceEl.offsetHeight + 'px';
      dropdownEl.style.left =
        left + faceEl.offsetWidth - dropdownEl.offsetWidth + 'px';
    } else if (position === 'bottomCenter') {
      dropdownEl.style.top = top + faceEl.offsetHeight + 'px';
      dropdownEl.style.left =
        left - dropdownEl.offsetWidth / 2 + faceEl.offsetWidth / 2 + 'px';
    }
  }, [faceRef, position]);

  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles} ref={dropdownRef}>
      {children}
    </div>
  );
};
