import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { AddReviewBody } from './AddReviewBody/AddReviewBody';
import {
  closeAddReview,
  selectAddReview,
} from 'store/addReview/addReview.slice';

export const AddReview = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const addReview = useSelector(selectAddReview);

  return (
    <Transition isActive={addReview.ui.addReviewIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeAddReview())}
            className={transitionStyles}
          >
            <AddReviewBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
