const initMore = () => {
  const list = document.querySelector('.entertainment__list');
  const moreButton = document.querySelector('.entertainment__more');
  moreButton?.addEventListener('click', () => {
    list?.classList.add('entertainment__list--all');
  });
};

const entertainment = {
  init: () => {
    initMore();
  },
};

export default entertainment;
