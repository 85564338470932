import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { closeFormModal, selectForm } from 'store/form/form.slice';
import { FormModalBody } from './FormModalBody/FormModalBody';

export const FormModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useSelector(selectForm);

  return (
    <Transition isActive={form.ui.modalIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeFormModal())}
            className={transitionStyles}
          >
            <FormModalBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
