import { FormEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  clearCallback,
  closeCallback,
  selectCallback,
  setCallbackName,
  setCallbackPhone,
  toggleCallbackPolicy,
} from 'store/callback/callback.slice';
import { TextField } from 'components/forms';
import { CloseButton, Policy } from 'components/elements';
import styles from './CallbackBody.module.scss';
import { openTextModal, openThanks } from 'store/app/app.slice';

declare const ym: any;
// declare const gtag: any;

export const CallbackBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const callback = useSelector(selectCallback);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(86936451, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    dispatch(closeCallback());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Обратный звонок',
        name: callback.name,
        phone: callback.phone,
      }),
    });

    dispatch(clearCallback());
  };

  return (
    <div className={styles.module}>
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeCallback())}
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Обратный звонок</h2>
        <TextField
          className={styles.name}
          placeholder="Имя"
          value={callback.name}
          onChange={(event) => dispatch(setCallbackName(event.target.value))}
        />
        <TextField
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="\d+"
          required
          value={callback.phone}
          onChange={(event) => dispatch(setCallbackPhone(event.target.value))}
        />
        <Policy
          className={styles.policy}
          checked={callback.policyIsChecked}
          onChange={() => dispatch(toggleCallbackPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
