import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { BookingState, BookingType } from './booking.types';

const initialState: BookingState = {
  room: null,
  type: 'booking',
  arrival: null,
  departure: null,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    bookingIsOpen: false,
    arrivalIsOpen: false,
    departureIsOpen: false,
    babiesIsOpen: false,
  },
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingArrival: (state, { payload }: PayloadAction<number | null>) => {
      state.arrival = payload;
    },
    setBookingDeparture: (state, { payload }: PayloadAction<number | null>) => {
      state.departure = payload;
    },

    changeBookingAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeBookingBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setBookingBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },

    setBookingName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setBookingPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    toggleBookingPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    openBookingArrival: (state) => {
      state.ui.arrivalIsOpen = true;
    },
    closeBookingArrival: (state) => {
      state.ui.arrivalIsOpen = false;
    },

    openBookingDeparture: (state) => {
      state.ui.departureIsOpen = true;
    },
    closeBookingDeparture: (state) => {
      state.ui.departureIsOpen = false;
    },

    openBookingBabies: (state) => {
      state.ui.babiesIsOpen = true;
    },
    closeBookingBabies: (state) => {
      state.ui.babiesIsOpen = false;
    },

    openBooking: (
      state,
      { payload }: PayloadAction<{ type: BookingType; room: string | null }>
    ) => {
      state.room = payload.room;
      state.type = payload.type;
      state.ui.bookingIsOpen = true;
    },
    closeBooking: (state) => {
      state.ui.bookingIsOpen = false;
    },
    clearBooking: (state) => {
      state.room = null;
      state.type = 'booking';
      state.arrival = null;
      state.departure = null;
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },
  },
});

export const {
  setBookingArrival,
  setBookingDeparture,

  changeBookingAdults,
  changeBookingBabies,
  setBookingBaby,

  setBookingName,
  setBookingPhone,
  toggleBookingPolicy,

  openBookingArrival,
  closeBookingArrival,

  openBookingDeparture,
  closeBookingDeparture,

  openBookingBabies,
  closeBookingBabies,

  openBooking,
  closeBooking,
  clearBooking,
} = bookingSlice.actions;

export const selectBooking = (state: RootState) => state.booking;

export const bookingReducer = bookingSlice.reducer;
