import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeTextModal, selectApp } from 'store/app/app.slice';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { TextModalBody } from './TextModalBody/TextModalBody';

export const TextModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const app = useSelector(selectApp);

  return (
    <Transition isActive={app.ui.textModalIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeTextModal())}
            className={transitionStyles}
          >
            <TextModalBody
              type={app.textModalType}
              onClose={() => dispatch(closeTextModal())}
            />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
