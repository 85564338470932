import cn from 'classnames';
import { PolicyProps } from './Policy.props';
import styles from './Policy.module.scss';
import { Checkbox } from 'components/forms';

export const Policy = ({
  checked,
  onChange,
  onClick,
  isInverted,
  className,
}: PolicyProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className, {
    [styles.isInverted]: isInverted,
  });

  return (
    <label className={moduleStyles}>
      <Checkbox
        className={styles.checkbox}
        checked={checked}
        onChange={onChange}
        required
        isInverted={isInverted}
      />
      <p className={styles.text}>
        <span>Даю согласие на обработку</span>{' '}
        <u
          onClick={(event) => {
            event.preventDefault();
            onClick();
          }}
        >
          персональных данных
        </u>
      </p>
    </label>
  );
};
