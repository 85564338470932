import cn from 'classnames';
import { ModalProps } from './Modal.props';
import styles from './Modal.module.scss';
import { useEffect, useRef } from 'react';

export const Modal = ({
  onOverlayClick,
  children,
  className,
}: ModalProps): JSX.Element => {
  // const modalRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (
  //     modalRef.current &&
  //     modalRef.current.parentElement &&
  //     modalRef.current.parentElement.scrollHeight
  //   ) {
  //     modalRef.current.style.height =
  //       modalRef.current.parentElement.scrollHeight + 'px';
  //   }
  // }, []);

  const moduleStyles = cn(styles.module, className);

  return (
    <section
      className={moduleStyles}
      // ref={modalRef}
      onClick={(event) =>
        event.target === event.currentTarget && onOverlayClick()
      }
    >
      <div className={styles.content}>{children}</div>
    </section>
  );
};
