import { Guests } from 'components/elements';
import { DatePicker } from 'components/forms';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  changeFormAdults,
  changeFormBabies,
  closeFormArrival,
  closeFormDeparture,
  closeFormGuests,
  openFormArrival,
  openFormDeparture,
  openFormGuests,
  openFormModal,
  selectForm,
  setFormArrival,
  setFormBaby,
  setFormDeparture,
} from 'store/form/form.slice';
import styles from './Form.module.scss';
import { FormModal } from './FormModal/FormModal';
import { FormTop } from './FormTop/FormTop';

export const Form = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useSelector(selectForm);

  const { arrival } = form;

  const handleClick = () => {
    if (!form.arrival) {
      dispatch(openFormArrival());
      return;
    }

    if (!form.departure) {
      dispatch(openFormDeparture());
      return;
    }

    dispatch(openFormModal());
  };

  return (
    <>
      <div className={styles.module}>
        <FormTop />

        <div className={styles.bottom}>
          <DatePicker
            className={styles.arrival}
            name="Дата заезда"
            isOpen={form.ui.arrivalIsOpen}
            disabledDaysBefore={new Date()}
            onClick={() =>
              dispatch(
                form.ui.arrivalIsOpen ? closeFormArrival() : openFormArrival()
              )
            }
            onOutsideClick={() => dispatch(closeFormArrival())}
            onDayClick={(date) => {
              batch(() => {
                dispatch(setFormArrival(date.valueOf()));
                dispatch(closeFormArrival());
              });
            }}
            value={form.arrival}
          />
          <DatePicker
            className={styles.departure}
            name="Дата отъезда"
            isOpen={form.ui.departureIsOpen}
            disabledDaysBefore={
              arrival ? new Date(arrival + 86400000) : new Date()
            }
            onClick={() =>
              dispatch(
                form.ui.departureIsOpen
                  ? closeFormDeparture()
                  : openFormDeparture()
              )
            }
            onOutsideClick={() => dispatch(closeFormDeparture())}
            onDayClick={(date) => {
              batch(() => {
                dispatch(setFormDeparture(date.valueOf()));
                dispatch(closeFormDeparture());
              });
            }}
            value={form.departure}
          />
          <Guests
            className={styles.guests}
            isOpen={form.ui.guestsIsOpen}
            onClick={() =>
              dispatch(
                form.ui.guestsIsOpen ? closeFormGuests() : openFormGuests()
              )
            }
            onOutsideOrConfirmClick={() => dispatch(closeFormGuests())}
            onChangeAdults={(amount) => dispatch(changeFormAdults(amount))}
            onChangeBabies={(amount) => dispatch(changeFormBabies(amount))}
            onSetBaby={(idx, age) => dispatch(setFormBaby({ idx, age }))}
            adults={form.adults}
            babies={form.babies}
          />
          <button className={styles.button} onClick={handleClick}>
            {form.type === 'booking' ? 'Забронировать' : 'Рассчитать стоимость'}
          </button>
        </div>
      </div>

      <FormModal />
    </>
  );
};
