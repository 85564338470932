import cn from 'classnames';
import { CheckboxProps } from './Checkbox.props';
import styles from './Checkbox.module.scss';

export const Checkbox = ({
  isInverted,
  className,
  ...rest
}: CheckboxProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className, {
    [styles.isInverted]: isInverted,
  });

  return <input className={moduleStyles} type="checkbox" {...rest} />;
};
