import Swiper, { Navigation, Pagination } from 'swiper';
// import { AddReview } from '../components/add-review';
// import { AddReviewProvider } from '../components/add-review';
// import { Review } from '../components/review';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.reviews__slider .swiper', {
    loop: true,
    spaceBetween: 20,
    // centeredSlides: true,
    loopedSlides: 10,

    navigation: {
      nextEl: '.reviews__next',
      prevEl: '.reviews__prev',
    },

    // pagination: {
    //   el: '.reviews__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      // 768: {
      //   slidesPerView: 2,
      //   spaceBetween: 30,
      // },
      1200: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  });
};

const initReviewsHeight = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll<HTMLDivElement>('.reviews__slide');

    let height = 243;
    // let height = 240;
    // if (window.innerWidth >= 768) {
    //   height = 240;
    // }

    items.forEach((item) => {
      const p = item.querySelector<HTMLParagraphElement>('.reviews__text');
      if (p!.offsetHeight > height) {
        item.classList.add('reviews__slide--cut');
      }
    });
  });
};

const initRating = () => {
  const span = document.querySelector<HTMLSpanElement>(
    '.reviews__subtitle span'
  );
  const items = document.querySelectorAll<HTMLDivElement>('.reviews__stars');
  let sum = 0;

  items.forEach(
    (item) => (sum += item.dataset.rating ? +item.dataset.rating : 0)
  );

  const res = Math.round((sum / items.length) * 10) / 10;

  if (span) {
    span.textContent = `${res}`;
  }
};

const reviews = {
  init: () => {
    initSlider();
    initReviewsHeight();
    initRating();
  },
};

export default reviews;
