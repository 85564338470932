import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeThanks, selectApp } from 'store/app/app.slice';
import { ThanksBody } from './ThanksBody/ThanksBody';

export const Thanks = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const app = useSelector(selectApp);

  return (
    <Transition isActive={app.ui.thanksIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeThanks())}
            className={transitionStyles}
          >
            <ThanksBody
              type={app.thanksModalType}
              onClose={() => dispatch(closeThanks())}
            />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
