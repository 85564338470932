import cn from 'classnames';
import { DatePickerProps } from './DatePicker.props';
import styles from './DatePicker.module.scss';
import { Dropdown } from 'components/layout';
import { Portal } from 'components/other';
import { useRef } from 'react';
import { Calendar } from 'components/other/Calendar/Calendar';

export const DatePicker = ({
  name,
  isOpen,
  onClick,
  onOutsideClick,
  onDayClick,
  value,
  disabledDaysBefore,
  disabledDaysAfter,
  className,
}: DatePickerProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className);

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <span className={styles.name}>{name}</span>
        <span className={styles.value}>
          {value && new Date(value).toLocaleDateString()}
        </span>
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideClick}
            position="bottomLeft"
          >
            <Calendar
              onDayClick={onDayClick}
              disabledDaysBefore={disabledDaysBefore}
              disabledDaysAfter={disabledDaysAfter}
            />
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
