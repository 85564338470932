import { Babies } from 'components/elements/Babies/Babies';
import { Select } from 'components/forms/Select/Select';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  changeQuizAdults,
  changeQuizBabies,
  closeQuizBabies,
  openQuizBabies,
  selectQuiz,
  setQuizBaby,
} from 'store/quiz/quiz.slice';
import { getAdultOptions, getBabyOptions } from 'utils/helpers/booking.helpers';
import styles from './QuizTwo.module.scss';

export const QuizTwo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <Select
        className={styles.adults}
        name="Кол-во взрослых"
        paddingLeft={170}
        options={getAdultOptions()}
        onChange={(event) => dispatch(changeQuizAdults(+event.target.value))}
        value={quiz.adults}
      />
      <Select
        className={styles.babies}
        name="Кол-во детей"
        paddingLeft={140}
        options={getBabyOptions()}
        onChange={(event) => dispatch(changeQuizBabies(+event.target.value))}
        value={quiz.babies.length}
      />
      {quiz.babies.length > 0 && (
        <Babies
          className={styles.babyList}
          isOpen={quiz.ui.babiesIsOpen}
          onClick={() =>
            dispatch(
              quiz.ui.babiesIsOpen ? closeQuizBabies() : openQuizBabies()
            )
          }
          onOutsideOrConfirmClick={() => dispatch(closeQuizBabies())}
          onSetBaby={(idx, age) => dispatch(setQuizBaby({ idx, age }))}
          babies={quiz.babies}
        />
      )}
    </div>
  );
};
