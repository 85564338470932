import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { AppState, TextModalType, ThanksModalType } from './app.types';

const initialState: AppState = {
  textModalType: 'policy',
  thanksModalType: 'request',
  ui: {
    textModalIsOpen: false,
    thanksIsOpen: false,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openTextModal: (state, { payload }: PayloadAction<TextModalType>) => {
      state.textModalType = payload;
      state.ui.textModalIsOpen = true;
    },
    closeTextModal: (state) => {
      state.ui.textModalIsOpen = false;
    },

    openThanks: (state, { payload }: PayloadAction<ThanksModalType>) => {
      state.thanksModalType = payload;
      state.ui.thanksIsOpen = true;
    },
    closeThanks: (state) => {
      state.ui.thanksIsOpen = false;
    },
  },
});

export const {
  openTextModal,
  closeTextModal,

  openThanks,
  closeThanks,
} = appSlice.actions;

export const selectApp = (state: RootState) => state.app;

export const appReducer = appSlice.reducer;
