// import { Textual } from 'components/elements';
import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';
import { useCookie } from 'hooks/useCookie';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { openTextModal } from 'store/app/app.slice';
import { CookieBody } from './CookieBody/CookieBody';

export const Cookie = () => {
  const dispatch = useAppDispatch();

  const [isShow, setIsShow] = useState(true);
  const [cookie, updateCookie] = useCookie('cookie_notification');

  return !cookie && isShow ? (
    <CookieBody
      onClose={() => setIsShow(false)}
      onPolicyClick={() => dispatch(openTextModal('cookie'))}
      onConfirm={() => updateCookie('confirmed', 365)}
    />
  ) : null;
};
