import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { App } from './App';
// import reportWebVitals from './reportWebVitals';

import header from './scripts/header';
import form from './scripts/form';
import about from './scripts/about';
import beach from './scripts/beach';
import entertainment from './scripts/entertainment';
import reviews from './scripts/reviews';
import rooms from './scripts/rooms';
import calculation from './scripts/calculation';

import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

header.init();
form.init();
about.init();
beach.init();
entertainment.init();
reviews.init();
rooms.init();
calculation.init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
