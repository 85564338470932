import SmoothScroll from 'smooth-scroll';

const headerEl = document.querySelector('.header');
const headerButton = document.querySelector('.header__button');

const initMobileMenu = () => {
  headerButton?.addEventListener('click', () => {
    document.body.classList.toggle('blocked');
    headerEl?.classList.toggle('header--open');
  });

  document.addEventListener('click', (event) => {
    if (
      headerEl?.classList.contains('header--open') &&
      event.target instanceof HTMLElement &&
      !headerEl?.contains(event.target)
    ) {
      document.body.classList.remove('blocked');
      headerEl.classList.remove('header--open');
    }
  });
};

const initNavButtons = () => {
  const buttons =
    document.querySelectorAll<HTMLAnchorElement>('.header__nav a');
  buttons.forEach((item) =>
    item.addEventListener('click', () => {
      document.body.classList.remove('blocked');
      headerEl?.classList.remove('header--open');
    })
  );
};

const initSmoothScroll = () => {
  new SmoothScroll('a[href*="#"]', {
    speed: 350,
  });
};

const header = {
  init: () => {
    initMobileMenu();
    initNavButtons();
    initSmoothScroll();
  },
};

export default header;
