import cn from 'classnames';
import { SelectProps } from './Select.props';
import styles from './Select.module.scss';

export const Select = ({
  name,
  paddingLeft,
  options,
  className,
  ...rest
}: SelectProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles}>
      {name && <span className={styles.name}>{name}</span>}
      <select
        className={styles.select}
        style={
          paddingLeft !== undefined
            ? { paddingLeft: `${paddingLeft}px` }
            : undefined
        }
        {...rest}
      >
        {options.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
};
