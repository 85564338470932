import { useRef } from 'react';
import cn from 'classnames';
import { GuestsProps } from './Guests.props';
import { Portal } from 'components/other';
import { Dropdown } from 'components/layout';
import { ageOptions } from 'utils/constants/ageOptions.constants';
import { Select } from 'components/forms/Select/Select';
import styles from './Guests.module.scss';

export const Guests = ({
  isOpen,
  onClick,
  onOutsideOrConfirmClick,
  onChangeAdults,
  onChangeBabies,
  onSetBaby,
  adults,
  babies,
  className,
}: GuestsProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className);

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <strong>Кол-во гостей</strong>
        <span>
          {adults}
          {babies.length > 0 && ` + ${babies.length}`}
        </span>
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideOrConfirmClick}
            position="bottomLeft"
          >
            <div className={styles.dropdown}>
              <div className={styles.adults}>
                <span className={styles.title}>Взрослых</span>
                <button
                  className={styles.button}
                  onClick={() => adults > 1 && onChangeAdults(adults - 1)}
                >
                  -
                </button>
                <span className={styles.value}>{adults}</span>
                <button
                  className={styles.button}
                  onClick={() => adults < 5 && onChangeAdults(adults + 1)}
                >
                  +
                </button>
              </div>

              <div className={styles.babies}>
                <span className={styles.title}>Детей</span>
                <button
                  className={styles.button}
                  onClick={() =>
                    babies.length > 0 && onChangeBabies(babies.length - 1)
                  }
                >
                  -
                </button>
                <span className={styles.value}>{babies.length}</span>
                <button
                  className={styles.button}
                  onClick={() =>
                    babies.length < 5 && onChangeBabies(babies.length + 1)
                  }
                >
                  +
                </button>
              </div>

              <div className={styles.list}>
                {babies.map((item, idx) => (
                  <div className={styles.item} key={idx}>
                    <span className={styles.name}>Ребенок {idx + 1}</span>
                    <Select
                      className={styles.select}
                      options={ageOptions}
                      paddingLeft={0}
                      value={item}
                      onChange={(event) => onSetBaby(idx, +event.target.value)}
                    />
                  </div>
                ))}
              </div>
              <button
                className={styles.confirm}
                onClick={onOutsideOrConfirmClick}
              >
                <span>Готово</span>
              </button>
            </div>
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
