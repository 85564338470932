import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { QuizState } from './quiz.types';

const initialState: QuizState = {
  step: 1,
  arrival: null,
  departure: null,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  ui: {
    arrivalIsOpen: false,
    departureIsOpen: false,
    babiesIsOpen: false,
  },
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizStep: (state, { payload }: PayloadAction<number>) => {
      state.step = payload;
    },

    setQuizArrival: (state, { payload }: PayloadAction<number | null>) => {
      state.arrival = payload;
    },
    setQuizDeparture: (state, { payload }: PayloadAction<number | null>) => {
      state.departure = payload;
    },

    changeQuizAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeQuizBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setQuizBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },

    setQuizName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setQuizPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    toggleQuizPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    openQuizArrival: (state) => {
      state.ui.arrivalIsOpen = true;
    },
    closeQuizArrival: (state) => {
      state.ui.arrivalIsOpen = false;
    },

    openQuizDeparture: (state) => {
      state.ui.departureIsOpen = true;
    },
    closeQuizDeparture: (state) => {
      state.ui.departureIsOpen = false;
    },

    openQuizBabies: (state) => {
      state.ui.babiesIsOpen = true;
    },
    closeQuizBabies: (state) => {
      state.ui.babiesIsOpen = false;
    },

    clearQuiz: (state) => {
      state.step = 1;
      state.arrival = null;
      state.departure = null;
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyIsChecked = false;
    },
  },
});

export const {
  setQuizStep,

  setQuizArrival,
  setQuizDeparture,

  changeQuizAdults,
  changeQuizBabies,
  setQuizBaby,

  setQuizName,
  setQuizPhone,
  toggleQuizPolicy,

  openQuizArrival,
  closeQuizArrival,

  openQuizDeparture,
  closeQuizDeparture,

  openQuizBabies,
  closeQuizBabies,

  clearQuiz,
} = quizSlice.actions;

export const selectQuiz = (state: RootState) => state.quiz;

export const quizReducer = quizSlice.reducer;
