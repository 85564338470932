import cn from 'classnames';
import { TextAreaProps } from './TextArea.props';
import styles from './TextArea.module.scss';

export const TextArea = ({
  className,
  ...rest
}: TextAreaProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return <textarea className={moduleStyles} {...rest} />;
};
