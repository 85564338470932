import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { ReviewBody } from './ReviewBody/ReviewBody';
import { closeReview, selectReview } from 'store/review/review.slice';

export const Review = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const review = useSelector(selectReview);

  return (
    <Transition isActive={review.ui.reviewIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeReview())}
            className={transitionStyles}
          >
            <ReviewBody
              text={review.text}
              name={review.name}
              grade={review.grade}
              date={review.date}
              onClose={() => dispatch(closeReview())}
            />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
