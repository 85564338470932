import cn from 'classnames';
import { Radio } from 'components/forms';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { changeFormType, selectForm } from 'store/form/form.slice';
import styles from './FormTop.module.scss';

export const FormTop = () => {
  const dispatch = useAppDispatch();
  const form = useSelector(selectForm);
  const { type } = form;

  return (
    <div className={styles.module}>
      <label
        className={cn(styles.label, {
          [styles.active]: type === 'calculation',
        })}
      >
        <Radio
          checked={type === 'calculation'}
          onChange={() => dispatch(changeFormType('calculation'))}
        />
        <span>Расчёт стоимости</span>
      </label>
      <label
        className={cn(styles.label, {
          [styles.active]: type === 'booking',
        })}
      >
        <Radio
          checked={type === 'booking'}
          onChange={() => dispatch(changeFormType('booking'))}
        />
        <span>Бронирование</span>
      </label>
    </div>
  );
};
