import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { AddReviewState } from './addReview.types';

const initialState: AddReviewState = {
  grade: 5,
  name: '',
  email: '',
  city: '',
  text: '',
  policyIsChecked: false,
  ui: {
    addReviewIsOpen: false,
  },
};

export const addReviewSlice = createSlice({
  name: 'addReview',
  initialState,
  reducers: {
    setAddReviewGrade: (state, { payload }: PayloadAction<number>) => {
      state.grade = payload;
    },
    setAddReviewName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setAddReviewEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setAddReviewCity: (state, { payload }: PayloadAction<string>) => {
      state.city = payload;
    },
    setAddReviewText: (state, { payload }: PayloadAction<string>) => {
      state.text = payload;
    },
    toggleAddReviewPolicy: (state) => {
      state.policyIsChecked = !state.policyIsChecked;
    },

    openAddReview: (state) => {
      state.ui.addReviewIsOpen = true;
    },
    closeAddReview: (state) => {
      state.ui.addReviewIsOpen = false;
    },
    clearAddReview: (state) => {
      state.grade = 5;
      state.name = '';
      state.email = '';
      state.city = '';
      state.text = '';
      state.policyIsChecked = false;
    },
  },
});

export const {
  setAddReviewGrade,
  setAddReviewName,
  setAddReviewEmail,
  setAddReviewCity,
  setAddReviewText,
  toggleAddReviewPolicy,

  openAddReview,
  closeAddReview,
  clearAddReview,
} = addReviewSlice.actions;

export const selectAddReview = (state: RootState) => state.addReview;

export const addReviewReducer = addReviewSlice.reducer;
