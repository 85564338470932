import { Slider } from 'components/layout';
import { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { openThanks } from 'store/app/app.slice';
import { clearQuiz, selectQuiz } from 'store/quiz/quiz.slice';
import styles from './Quiz.module.scss';
import { QuizControl } from './QuizControl/QuizControl';
import { QuizOne } from './QuizOne/QuizOne';
import { QuizThree } from './QuizThree/QuizThree';
import { QuizTwo } from './QuizTwo/QuizTwo';

declare const ym: any;
// declare const gtag: any;

export const Quiz = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useSelector(selectQuiz);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!quiz.arrival || !quiz.departure) {
      return;
    }

    ym(86936451, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    dispatch(openThanks('request'));

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Квиз',
        arrival: new Date(quiz.arrival).toLocaleDateString(),
        departure: new Date(quiz.departure).toLocaleDateString(),
        adults: quiz.adults,
        babies: quiz.babies,
        name: quiz.name,
        phone: quiz.phone,
      }),
    });

    dispatch(clearQuiz());
  };

  return (
    <div className={styles.module}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Slider offsetBy={quiz.step - 1} className={styles.slider}>
          <QuizOne />
          <QuizTwo />
          <QuizThree />
        </Slider>
        <QuizControl />
      </form>
    </div>
  );
};
