import { FormEventHandler, MouseEventHandler } from 'react';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { openTextModal, openThanks } from 'store/app/app.slice';
import {
  changeBookingAdults,
  changeBookingBabies,
  closeBookingArrival,
  closeBookingBabies,
  closeBooking,
  closeBookingDeparture,
  openBookingArrival,
  openBookingBabies,
  openBookingDeparture,
  selectBooking,
  setBookingArrival,
  setBookingBaby,
  setBookingDeparture,
  setBookingName,
  setBookingPhone,
  toggleBookingPolicy,
  clearBooking,
} from 'store/booking/booking.slice';
import { DatePicker, TextField } from 'components/forms';
import { CloseButton, Policy } from 'components/elements';
import styles from './BookingBody.module.scss';
import { Select } from 'components/forms/Select/Select';
import { getAdultOptions, getBabyOptions } from 'utils/helpers/booking.helpers';
import { Babies } from 'components/elements/Babies/Babies';

declare const ym: any;
// declare const gtag: any;

export const BookingBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const booking = useSelector(selectBooking);
  const {
    type,
    room,
    arrival,
    departure,
    adults,
    babies,
    name,
    phone,
    policyIsChecked,
    ui,
  } = booking;

  const title = {
    booking: 'Бронирование',
    calculation: 'Расчет стоимости',
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (arrival === null) {
      dispatch(openBookingArrival());
      return;
    }
    if (departure === null) {
      dispatch(openBookingDeparture());
      return;
    }

    ym(86936451, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    dispatch(closeBooking());
    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: title[type],
        room,
        arrival: new Date(arrival).toLocaleDateString(),
        departure: new Date(departure).toLocaleDateString(),
        adults,
        babies,
        name,
        phone,
      }),
    });

    dispatch(clearBooking());
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (arrival === null) {
      dispatch(openBookingArrival());
      event.preventDefault();
      return;
    }
    if (departure === null) {
      dispatch(openBookingDeparture());
      event.preventDefault();
      return;
    }
  };

  return (
    <div className={styles.module}>
      <img className={styles.bg1} src="/build/images/bg-1.svg" alt="bg" />
      <img className={styles.bg2} src="/build/images/bg-2.svg" alt="bg" />
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeBooking())}
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>{title[type]}</h2>
        <DatePicker
          className={styles.arrival}
          name="Дата заезда"
          isOpen={ui.arrivalIsOpen}
          disabledDaysBefore={new Date()}
          onClick={() =>
            dispatch(
              ui.arrivalIsOpen ? closeBookingArrival() : openBookingArrival()
            )
          }
          onOutsideClick={() => dispatch(closeBookingArrival())}
          onDayClick={(date) => {
            batch(() => {
              dispatch(setBookingArrival(date.valueOf()));
              dispatch(closeBookingArrival());
            });
          }}
          value={arrival}
        />
        <DatePicker
          className={styles.arrival}
          name="Дата отъезда"
          isOpen={ui.departureIsOpen}
          disabledDaysBefore={
            arrival ? new Date(arrival + 86400000) : new Date()
          }
          onClick={() =>
            dispatch(
              ui.departureIsOpen
                ? closeBookingDeparture()
                : openBookingDeparture()
            )
          }
          onOutsideClick={() => dispatch(closeBookingDeparture())}
          onDayClick={(date) => {
            batch(() => {
              dispatch(setBookingDeparture(date.valueOf()));
              dispatch(closeBookingDeparture());
            });
          }}
          value={departure}
        />
        <Select
          className={styles.adults}
          name="Кол-во взрослых"
          paddingLeft={170}
          options={getAdultOptions()}
          onChange={(event) =>
            dispatch(changeBookingAdults(+event.target.value))
          }
          value={adults}
        />
        <Select
          className={styles.babies}
          name="Кол-во детей"
          paddingLeft={140}
          options={getBabyOptions()}
          onChange={(event) =>
            dispatch(changeBookingBabies(+event.target.value))
          }
          value={babies.length}
        />
        {babies.length > 0 && (
          <Babies
            className={styles.babyList}
            isOpen={ui.babiesIsOpen}
            onClick={() =>
              dispatch(
                ui.babiesIsOpen ? closeBookingBabies() : openBookingBabies()
              )
            }
            onOutsideOrConfirmClick={() => dispatch(closeBookingBabies())}
            onSetBaby={(idx, age) => dispatch(setBookingBaby({ idx, age }))}
            babies={babies}
          />
        )}
        <TextField
          className={styles.name}
          placeholder="Имя"
          value={name}
          onChange={(event) => dispatch(setBookingName(event.target.value))}
        />
        <TextField
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="\d+"
          required
          value={phone}
          onChange={(event) => dispatch(setBookingPhone(event.target.value))}
        />
        <Policy
          className={styles.policy}
          checked={policyIsChecked}
          onChange={() => dispatch(toggleBookingPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button} onClick={handleConfirm}>
          Отправить
        </button>
      </form>
    </div>
  );
};
